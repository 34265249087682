import "../Styles/index.scss";

import { Fancybox } from "@fancyapps/ui";
import tippy from "tippy.js";
import { Vuetify3Dialog } from "vuetify3-dialog";
import { defineCustomElement as defineCustomElementCore, createApp, type App, type Component } from "vue";
import { mdiClose } from "@mdi/js";
import { initializeMaps } from "@wagich/bluc-map";

import { vuetify } from "./vuetify";
import { ContactForm } from "./contact-form";

document.querySelector("#navToggle")?.addEventListener("click", () => {
	document.querySelector("nav")?.classList.toggle("is-open")
});

for (let element of document.querySelectorAll(".courseBookingAction")) {
	element.addEventListener("click", e => {
		let url = (e.target as HTMLElement).getAttribute("href")!;
		window.open(url, "BookingWindow", "width=800, height=500");
		e.preventDefault();
	});
}

Fancybox.bind(".is-zoomable");
tippy("[data-tippy-content]", {
	allowHTML: true,
	appendTo: "parent",
});
tippy("[data-tippy-element]", {
	allowHTML: true,
	appendTo: "parent",
	content: reference => {
		let tooltip = document.querySelector<HTMLElement>(reference.getAttribute("data-tippy-element")!);
		tooltip?.style.removeProperty("display");
		return tooltip ?? "";
	},
});

document.querySelector(".footerContact.open-contactform")?.addEventListener("click", e => {
	let target = document.createElement("div");
	target.className = "contact-form-overlay";
	document.body.appendChild(target);

	let app = createApp(ContactForm, { source: "Bodyfeet Plus" });
	app.use(vuetify);
	app.use(Vuetify3Dialog);
	app.config.globalProperties.$icons = {
		mdiClose,
	};
	app.mount(target);

	e.preventDefault();
});
document.addEventListener("close-contact-form", () => {
	document.querySelector(".contact-form-overlay")?.remove();
});

initializeMaps();
