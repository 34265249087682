import { createVuetify } from "vuetify";

import { de } from "vuetify/locale";
import {
	VBtn, VIcon, VBadge, VTooltip, VDivider, VSnackbar, VImg, VAvatar, VProgressCircular, VSheet,
	VContainer, VCol, VRow, VSpacer, 
	VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle,
	VTextField, VRadio, VRadioGroup, VCheckbox, VBtnToggle, VChip, VDatePicker, VAutocomplete, VTextarea, VInput,

} from "vuetify/components";
import { VDateInput } from "vuetify/labs/components";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";

export const vuetify = createVuetify({
	components: {
		VBtn, VIcon, VBadge, VTooltip, VDivider, VSnackbar, VImg, VAvatar, VProgressCircular, VSheet,
		VContainer, VCol, VRow, VSpacer,
		VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle,
		VTextField, VRadio, VRadioGroup, VCheckbox, VBtnToggle, VChip, VDatePicker, VAutocomplete, VTextarea, VInput,
		VDateInput,
	},
	locale: {
		locale: "de",
		messages: { de },
	},
	icons: {
		defaultSet: "mdi",
		aliases,
		sets: {
			mdi,
		},
	},
	theme: {
		defaultTheme: "light",
		variations: false,
		themes: {
			light: {
				dark: false,
				colors: {
					primary: "#7a0658",
					accent: "#7a0658",
					grey: "#9E9E9E",
				}
			}
		}
	}
});
