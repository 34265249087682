import { Vue, Component, Prop, Watch, Inject, Provide } from "@wagich/vue-facing-decorator-metadata";
import { create, enforce, omitWhen, only, test, warn, type SuiteResult } from "vest";
import "vest/enforce/email";

enum State {
	Initial = "initial",
	Loading = "loading",
	Success = "success",
	Error = "error"
};

enum Gender {
	Female = "female",
	Male = "male"
};

import { render } from "./contact-form.html";

export const Event_Success = "success";
export const Event_Cancel = "cancel";

interface ContactRequest {
	gender: string | null;
	firstname: string;
	lastname: string;
	phone: string;
	email: string;
	message: string;
}

const emptyData = {
	firstname: "",
	lastname: "",
	phone: "",
	email: "",
	message: "",
	gender: null,
};

const validator = create((data: ContactRequest, fieldName: string | undefined = undefined) => {
	only(fieldName);

	test("gender", "Bitte wähle eine Anrede aus", () => {
		enforce(data.gender).inside([Gender.Male, Gender.Female]);
	});

	test("firstname", "Bitte gib deinen Vornamen ein", () => {
		enforce(data.firstname).isNotEmpty();
	});

	test("lastname", "Bitte gib deinen Nachnamen ein", () => {
		enforce(data.lastname).isNotEmpty();
	});

	test("phone", "Bitte gib deine Telefonnummer ein", () => {
		enforce(data.phone).isNotEmpty();
	});

	test("email", () => {
		enforce(data.email)
			.message("Bitte gib deine E-Mail-Adresse ein")
			.isNotEmpty()
			.message("Bitte gib eine gültige E-Mail-Adresse ein")
			.isEmail();
	});

	test("message", "Bitte schreib uns, wie wir dir weiterhelfen können", () => {
		enforce(data.message).isNotEmpty();
	});
});


@Component({
	render,
})
export class ContactForm extends Vue {
	validations: SuiteResult<string, string> = validator.get();
	state: State = State.Initial;

	data: ContactRequest = Object.assign({}, emptyData);

	@Prop({ default: "" }) source: string;
	@Prop({ required: false }) token: string | null;

	get maleLabel(): string {
		return this.data.firstname ? `Lieber ${this.data.firstname}` : `Lieber Max`;
	}
	get femaleLabel(): string {
		return this.data.firstname ? `Liebe ${this.data.firstname}` : `Liebe Maxine`;
	}

	cancel() {
		this.reset();
		this.$emit(Event_Cancel)
		document.dispatchEvent(new CustomEvent("close-contact-form"));
	}

	reset() {
		this.state = State.Initial;
		this.data = Object.assign({}, emptyData);
	}

	async validate(fieldName?: string) {
		await this.$nextTick();
		this.validations = validator(this.data, fieldName);
	}

	async send() {
		await this.validate();
		if (!this.validations.isValid()) {
			return;
		}

		this.state = State.Loading;

		try {
			let apiUrl = new URL("/api/contact", window.location.href);
			if (this.token != null) {
				apiUrl.searchParams.set("token", this.token);
			}

			let responseMessage = await fetch(apiUrl, {
				body: JSON.stringify({
					name: `${this.data.gender === Gender.Male ? "Herr" : "Frau"} ${this.data.firstname} ${this.data.lastname}`,
					phone: this.data.phone,
					email: this.data.email,
					message: this.data.message,
					source: this.source ?? document.title,
					token: this.token,
				}),
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			});

			if (!responseMessage.ok) {
				this.state = State.Error;
				this.$notify.error("Das Formular konnte aus technischen Gründen nicht gesendet werden. Bitte kontaktiere uns per Telefon oder schreibe uns eine Mail an mail@bodyfeet.ch. Vielen Dank für dein Verständnis.", { location: "top center", timeout: 10000 });
				return;
			}

			if (this.token != null) {
				try {
					gtag("event", "generate_lead", { event_category: "Direktkontakt-Formular" });
				} catch (ex) { }
				try {
					_paq.push(["trackEvent", "Formulare", "Direktkontakt-Formular abgeschickt"]);
				} catch { }
			} else {
				try {
					gtag("event", "generate_lead", { event_category: "Kontaktformular" });
				} catch (ex) { }
				try {
					_paq.push(["trackEvent", "Formulare", "Kontaktformular abgeschickt"]);
				} catch { }
			}

			this.state = State.Success;
			this.$notify.success("Vielen Dank für deine Nachricht. Wir melden uns in Kürze bei dir.", { location: "top center", timeout: 10000 });
			this.$emit(Event_Success);
		} catch (ex) {
			this.state = State.Error;
			this.$notify.error("Das Formular konnte aus technischen Gründen nicht gesendet werden. Bitte kontaktiere uns per Telefon oder schreibe uns eine Mail an mail@bodyfeet.ch. Vielen Dank für dein Verständnis.", { location: "top center", timeout: 10000 });
		}
	}
}
